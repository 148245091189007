var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.skeletonLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"min-height":"600px","type":"article, article, article, actions"}})],1)],1)],1):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.registrarItem.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-3",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-subheader',{staticClass:"text-h5 black--text"},[_vm._v(" Información general del proyecto ")])],1)],1),_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text "},[_vm._v(" Fuente cooperante ")])],1),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12","md":"12"}},[_c('v-divider',{staticClass:"mt-0 pt-0"})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.tiposFuenteCooperante,"loading":false,"dense":"","filled":"","disabled":true,"label":"Tipo fuente cooperante","item-text":"nombreTipoFuente","item-value":"id","return-object":"","rules":[_vm.selectRequired('nombre del tipo fuente cooperante')],"no-data-text":_vm.tiposFuenteCooperante != null
                                ? 'Selecciona un tipo de fuente cooperante'
                                : 'No se han encontrado tipos de fuente cooperante',"menu-props":"auto"},on:{"change":function($event){return _vm.configurarFuentes()}},model:{value:(_vm.tipoFuenteUsuario),callback:function ($$v) {_vm.tipoFuenteUsuario=$$v},expression:"tipoFuenteUsuario"}})],1)],1),_c('v-row',[(_vm.tipoFuenteUsuario.id == 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{class:_vm.tipoFuenteUsuario.id == 1 ? 'required' : '',attrs:{"items":_vm.fuentesBilaterales,"loading":false,"required":_vm.tipoFuenteUsuario.id == 1,"dense":"","filled":"","disabled":_vm.tipoFuenteUsuario.id !=1,"label":"País Fuente Bilateral","item-text":"pais","item-value":"id","return-object":"","rules":[
                            _vm.tipoFuenteUsuario.id === 1 ? _vm.selectRequired('país bilateral') : true ],"no-data-text":_vm.fuentesBilaterales != null
                            ? 'Selecciona un país'
                            : 'No se han encontrado países',"menu-props":"auto"},on:{"change":function($event){_vm.obtenerAgenciasPorFuenteBilateral(); _vm.agenciaSeleccionada={};}},model:{value:(_vm.fuenteBilateralSeleccionada),callback:function ($$v) {_vm.fuenteBilateralSeleccionada=$$v},expression:"fuenteBilateralSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteUsuario.id == 1)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.agencias,"loading":_vm.ddAgenciasLoading,"clearable":true,"dense":"","filled":"","disabled":_vm.tipoFuenteUsuario.id != 1,"label":"Seleccione Agencia","item-text":"nombreAgencia","item-value":"id","return-object":"","no-data-text":_vm.agencias.length > 0
                            ? 'Selecciona una agencia'
                            : 'No se han encontrado agencias',"menu-props":"auto"},model:{value:(_vm.agenciaSeleccionada),callback:function ($$v) {_vm.agenciaSeleccionada=$$v},expression:"agenciaSeleccionada"}})],1):_vm._e()],1),_c('v-row',[(_vm.tipoFuenteUsuario.id == 2)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.fuentesMultilateral,"loading":false,"dense":"","filled":"","disabled":_vm.tipoFuenteUsuario.id == 1,"label":"Seleccione fuente","item-text":"nombreFuenteMultilateral","item-value":"id","return-object":"","no-data-text":_vm.fuentesMultilateral.length > 0
                                ? 'Seleccione una fuente multilateral'
                                : 'No se han encontrado fuentes multilaterales',"menu-props":"auto"},on:{"change":function($event){_vm.obtenerAgenciasMultilaterales(_vm.fuenteMultilateralSeleccionada.id); _vm.agenciaMultilateralSeleccionada={};}},model:{value:(_vm.fuenteMultilateralSeleccionada),callback:function ($$v) {_vm.fuenteMultilateralSeleccionada=$$v},expression:"fuenteMultilateralSeleccionada"}})],1):_vm._e(),(_vm.tipoFuenteUsuario.id == 2)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.agenciasMultilaterales,"clearable":true,"disabled":_vm.tipoFuenteUsuario.id == 1,"loading":_vm.ddAgenciasMultiLoading,"dense":"","filled":"","label":"Seleccione Agencia Multilateral","item-text":"agencia","item-value":"id","return-object":"","no-data-text":_vm.agenciasMultilaterales > 0
                                ? 'Selecciona una agencia'
                                : 'No se han encontrado agencias para la fuente seleccionada',"menu-props":"auto"},model:{value:(_vm.agenciaMultilateralSeleccionada),callback:function ($$v) {_vm.agenciaMultilateralSeleccionada=$$v},expression:"agenciaMultilateralSeleccionada"}})],1):_vm._e()],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.tipo===1,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Nombre del proyecto","rules":[
                        _vm.required('nombre del proyecto'),
                        _vm.minLength('nombre del proyecto', 5),
                        _vm.maxLength('nombre del proyecto', 500)
                    ],"rows":"3","maxlength":"500"},model:{value:(_vm.datosItem.nombreProyecto),callback:function ($$v) {_vm.$set(_vm.datosItem, "nombreProyecto", $$v)},expression:"datosItem.nombreProyecto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{staticClass:"required",attrs:{"dense":"","disabled":_vm.tipo===1,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Objetivo del proyecto","rules":[
                        _vm.required('objetivo del proyecto'),
                        _vm.minLength('objetivo del proyecto', 5),
                        _vm.maxLength('objetivo del proyecto', 2000)
                    ],"rows":"3","maxlength":"2000"},model:{value:(_vm.datosItem.objetivo),callback:function ($$v) {_vm.$set(_vm.datosItem, "objetivo", $$v)},expression:"datosItem.objetivo"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 mt-2",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.sectoresBeneficiados,"loading":false,"dense":"","disabled":_vm.tipo===1,"filled":"","label":"Seleccione el sector principal que beneficia","item-text":"sector","item-value":"id","no-data-text":_vm.sectoresBeneficiados != null
                        ? 'Seleccione un sector beneficiado'
                        : 'No se han encontrado sectores beneficiado',"menu-props":"auto"},model:{value:(_vm.datosItem.sectoresId),callback:function ($$v) {_vm.$set(_vm.datosItem, "sectoresId", $$v)},expression:"datosItem.sectoresId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","disabled":_vm.tipo===1,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Fecha de suscripción","rules":[
                        _vm.required('Fecha de suscripción'),
                        _vm.minLength('Fecha de suscripción', 8),
                        _vm.dateFormat('Fecha de suscripción')
                    ],"maxlength":"10"},model:{value:(_vm.fechaSuscripcion),callback:function ($$v) {_vm.fechaSuscripcion=$$v},expression:"fechaSuscripcion"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","disabled":_vm.tipo===1,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Fecha de inicio","rules":[
                        _vm.required('Fecha de inicio del proyecto'),
                        _vm.minLength('Fecha de inicio del proyecto', 8),
                        _vm.dateFormat('Fecha de inicio del proyecto')
                    ],"maxlength":"10"},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"4","sm":"6","xs":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"required",attrs:{"dense":"","disabled":_vm.tipo===1,"filled":"","autocomplete":"off","color":"blue-grey lighten-2","label":"Fecha de finalización","rules":[
                        _vm.required('Fecha de finalización'),
                        _vm.minLength('Fecha de finalización', 8),
                        _vm.dateFormat('Fecha de finalización')
                    ],"maxlength":"50"},model:{value:(_vm.fechaFinalizacion),callback:function ($$v) {_vm.fechaFinalizacion=$$v},expression:"fechaFinalizacion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.modalidadesEjecucion,"loading":false,"dense":"","disabled":_vm.tipo===1,"filled":"","label":"Modalidad de ejecución","item-text":"modalidad","item-value":"id","return-object":"","no-data-text":_vm.modalidadesEjecucion != null
                        ? 'Seleccione una modalidad'
                        : 'No se han encontrado modalidades de ejecución',"menu-props":"auto"},model:{value:(_vm.modalidadEjecucionSeleccionada),callback:function ($$v) {_vm.modalidadEjecucionSeleccionada=$$v},expression:"modalidadEjecucionSeleccionada"}})],1)],1),(1>1)?_c('v-card',{staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-4 pb-0 mb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-subheader',{staticClass:"text-h6 black--text "},[_vm._v(" Datos generales de cooperación ")])],1),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.programaConjuntoItems,"loading":false,"dense":"","disabled":_vm.tipo===1,"filled":"","label":"El proyecto es parte de un programa conjunto?","item-text":"text","item-value":"id","menu-props":"auto"},model:{value:(_vm.programaConjunto),callback:function ($$v) {_vm.programaConjunto=$$v},expression:"programaConjunto"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.modalidadesEjecucion,"loading":false,"dense":"","disabled":_vm.tipo===1,"filled":"","label":"Fuente Cooperante","item-text":"nombreModalidad","item-value":"id","return-object":"","no-data-text":_vm.modalidadesEjecucion != null
                                ? 'Seleccione una modalidad'
                                : 'No se han encontrado modalidades de ejecución',"menu-props":"auto"},model:{value:(_vm.modalidadEjecucionSeleccionada),callback:function ($$v) {_vm.modalidadEjecucionSeleccionada=$$v},expression:"modalidadEjecucionSeleccionada"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-select',{staticClass:"required",attrs:{"items":_vm.modalidadesEjecucion,"loading":false,"dense":"","filled":"","disabled":_vm.tipo===1,"label":"Agencia Implementadora","item-text":"nombreModalidad","item-value":"id","return-object":"","no-data-text":_vm.modalidadesEjecucion != null
                                ? 'Seleccione una modalidad'
                                : 'No se han encontrado modalidades de ejecución',"menu-props":"auto"},model:{value:(_vm.modalidadEjecucionSeleccionada),callback:function ($$v) {_vm.modalidadEjecucionSeleccionada=$$v},expression:"modalidadEjecucionSeleccionada"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{staticClass:"required",attrs:{"dense":"","filled":"","disabled":_vm.tipo===1,"label":"Monto","item-text":"nombreModalidad","item-value":"id","return-object":"","no-data-text":_vm.modalidadesEjecucion != null
                                ? 'Seleccione una modalidad'
                                : 'No se han encontrado modalidades de ejecución',"menu-props":"auto"},model:{value:(_vm.datosCooperacion.monto),callback:function ($$v) {_vm.$set(_vm.datosCooperacion, "monto", $$v)},expression:"datosCooperacion.monto"}})],1)],1)],1)],1):_vm._e(),(_vm.tipo===2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right mt-2",attrs:{"color":"light-blue-502","type":"submit","elevation":0,"disabled":!_vm.validForm,"loading":_vm.btnRegistroLoading}},[_vm._v(" "+_vm._s(_vm.btnRegistroText)+" ")])],1)],1):_vm._e()],1),_c('SnackAlert',{ref:"snackalert"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }