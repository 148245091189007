<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>
<div v-if="!skeletonLoading">
    <v-form
        ref="form"
        v-on:submit.prevent="registrarItem"
        v-model="validForm"
        >

            <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                <v-subheader class="text-h5 black--text">
                    Información general del proyecto
                </v-subheader>
            </v-col>
        </v-row>


        <v-card class="mt-6" outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                        <v-subheader class="text-h6 black--text ">
                        Fuente cooperante
                        </v-subheader>
                    </v-col>

                   
                    <v-col cols="12" md="12" class="pt-0 mt-0">
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                    <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                        <v-select
                            v-model="tipoFuenteUsuario"
                            :items="tiposFuenteCooperante"
                            :loading="false"
                            dense
                            filled
                            class="required"
                            :disabled="true"
                            label="Tipo fuente cooperante"
                            item-text="nombreTipoFuente"
                            item-value="id"
                            return-object
                            :rules="[selectRequired('nombre del tipo fuente cooperante')]"
                            :no-data-text="
                                tiposFuenteCooperante != null
                                ? 'Selecciona un tipo de fuente cooperante'
                                : 'No se han encontrado tipos de fuente cooperante'
                            "
                            @change="configurarFuentes()"
                            menu-props="auto"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row>

                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0" v-if="tipoFuenteUsuario.id == 1">
                        <v-select
                        v-model="fuenteBilateralSeleccionada"
                        :items="fuentesBilaterales"
                        :loading="false"
                        :required="tipoFuenteUsuario.id == 1"
                        dense
                        :class="tipoFuenteUsuario.id == 1 ? 'required' : ''"
                        filled
                        :disabled="tipoFuenteUsuario.id !=1"
                        label="País Fuente Bilateral"
                        item-text="pais"
                        item-value="id"
                        return-object
                        :rules="[
                            tipoFuenteUsuario.id === 1 ? selectRequired('país bilateral') : true,
                        ]"
                        :no-data-text="
                            fuentesBilaterales != null
                            ? 'Selecciona un país'
                            : 'No se han encontrado países'
                        "
                        @change="obtenerAgenciasPorFuenteBilateral(); agenciaSeleccionada={};"
                        menu-props="auto"
                        ></v-select>
                    </v-col>

                    <!-- inicio:: agencias -->
                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0" v-if="tipoFuenteUsuario.id == 1">
                        <v-select
                        v-model="agenciaSeleccionada"
                        :items="agencias"
                        :loading="ddAgenciasLoading"
                        :clearable="true"
                        dense
                        filled
                        :disabled="tipoFuenteUsuario.id != 1"
                        label="Seleccione Agencia"
                        item-text="nombreAgencia"
                        item-value="id"
                        return-object
                        :no-data-text="
                            agencias.length > 0
                            ? 'Selecciona una agencia'
                            : 'No se han encontrado agencias'
                        "
                        menu-props="auto"
                        ></v-select>
                    </v-col>
                    <!-- fin:: agencias -->
                </v-row>

                <v-row>

                    <v-col cols="12" md="6" sm="6" class="pt-0 pb-0" v-if="tipoFuenteUsuario.id == 2">
                        <v-select
                            v-model="fuenteMultilateralSeleccionada"
                            :items="fuentesMultilateral"
                            :loading="false"
                            dense
                            filled
                            :disabled="tipoFuenteUsuario.id == 1"
                            label="Seleccione fuente"
                            item-text="nombreFuenteMultilateral"
                            item-value="id"
                            return-object
                            :no-data-text="
                                fuentesMultilateral.length > 0
                                ? 'Seleccione una fuente multilateral'
                                : 'No se han encontrado fuentes multilaterales'
                            "
                            @change="obtenerAgenciasMultilaterales(fuenteMultilateralSeleccionada.id); agenciaMultilateralSeleccionada={};"
                            menu-props="auto"
                        ></v-select>
                    </v-col>

                    <!-- inicio:: agencias multi-->
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0" v-if="tipoFuenteUsuario.id == 2">
                        <v-select
                            v-model="agenciaMultilateralSeleccionada"
                            :items="agenciasMultilaterales"
                            :clearable="true"
                            :disabled="tipoFuenteUsuario.id == 1"
                            :loading="ddAgenciasMultiLoading"
                            dense
                            filled
                            label="Seleccione Agencia Multilateral"
                            item-text="agencia"
                            item-value="id"
                            return-object
                            :no-data-text="
                            agenciasMultilaterales > 0
                                ? 'Selecciona una agencia'
                                : 'No se han encontrado agencias para la fuente seleccionada'
                            "
                            menu-props="auto"
                        ></v-select>
                    </v-col>
                    <!-- fin:: agencias multi -->
                </v-row> 
            </v-card-text>
        </v-card>

        <v-row class="mt-4">

            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                    dense
                    :disabled="tipo===1"
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosItem.nombreProyecto"
                    label="Nombre del proyecto"
                    :rules="[
                        required('nombre del proyecto'),
                        minLength('nombre del proyecto', 5),
                        maxLength('nombre del proyecto', 500)
                    ]"
                    rows="3"
                    maxlength="500"
                ></v-textarea>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                    dense
                    :disabled="tipo===1"
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="datosItem.objetivo"
                    label="Objetivo del proyecto"
                    :rules="[
                        required('objetivo del proyecto'),
                        minLength('objetivo del proyecto', 5),
                        maxLength('objetivo del proyecto', 2000)
                    ]"
                    rows="3"
                    maxlength="2000"
                ></v-textarea>
            </v-col>
        </v-row>
        

        <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-0 mt-2">
                <v-select
                    v-model="datosItem.sectoresId"
                    :items="sectoresBeneficiados"
                    :loading="false"
                    dense
                    :disabled="tipo===1"
                    class="required"
                    filled
                    label="Seleccione el sector principal que beneficia"
                    item-text="sector"
                    item-value="id"
                    :no-data-text="
                    sectoresBeneficiados != null
                        ? 'Seleccione un sector beneficiado'
                        : 'No se han encontrado sectores beneficiado'
                    "
                    menu-props="auto"
                ></v-select>
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                    dense
                    :disabled="tipo===1"
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="fechaSuscripcion"
                    label="Fecha de suscripción"
                    :rules="[
                        required('Fecha de suscripción'),
                        minLength('Fecha de suscripción', 8),
                        dateFormat('Fecha de suscripción')
                    ]"
                    maxlength="10"
                    v-mask="'##/##/####'"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                    dense
                    :disabled="tipo===1"
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="fechaInicio"
                    label="Fecha de inicio"
                    :rules="[
                        required('Fecha de inicio del proyecto'),
                        minLength('Fecha de inicio del proyecto', 8),
                        dateFormat('Fecha de inicio del proyecto')
                    ]"
                    maxlength="10"
                    v-mask="'##/##/####'"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                    dense
                    :disabled="tipo===1"
                    filled
                    autocomplete="off"
                    class="required"
                    color="blue-grey lighten-2"
                    v-model="fechaFinalizacion"
                    label="Fecha de finalización"
                    :rules="[
                        required('Fecha de finalización'),
                        minLength('Fecha de finalización', 8),
                        dateFormat('Fecha de finalización')
                    ]"
                    maxlength="50"
                    v-mask="'##/##/####'"
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                    v-model="modalidadEjecucionSeleccionada"
                    :items="modalidadesEjecucion"
                    :loading="false"
                    dense
                    :disabled="tipo===1"
                    filled
                    class="required"
                    label="Modalidad de ejecución"
                    item-text="modalidad"
                    item-value="id"
                    return-object
                    :no-data-text="
                        modalidadesEjecucion != null
                        ? 'Seleccione una modalidad'
                        : 'No se han encontrado modalidades de ejecución'
                    "
                    menu-props="auto"
                ></v-select>
            </v-col>
        </v-row>


        <v-card class="mt-6" outlined v-if="1>1">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-0 mt-4 pb-0 mb-0">
                        <v-subheader class="text-h6 black--text ">
                            Datos generales de cooperación
                        </v-subheader>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pb-0 mb-0">
                        <v-select
                            v-model="programaConjunto"
                            :items="programaConjuntoItems"
                            :loading="false"
                            dense
                            :disabled="tipo===1"
                            filled
                            class="required"
                            label="El proyecto es parte de un programa conjunto?"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            >
                        </v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                            v-model="modalidadEjecucionSeleccionada"
                            :items="modalidadesEjecucion"
                            :loading="false"
                            dense
                            :disabled="tipo===1"
                            filled
                            class="required"
                            label="Fuente Cooperante"
                            item-text="nombreModalidad"
                            item-value="id"
                            return-object
                            :no-data-text="
                                modalidadesEjecucion != null
                                ? 'Seleccione una modalidad'
                                : 'No se han encontrado modalidades de ejecución'
                            "
                            menu-props="auto"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-select
                            v-model="modalidadEjecucionSeleccionada"
                            :items="modalidadesEjecucion"
                            :loading="false"
                            dense
                            filled
                            :disabled="tipo===1"
                            class="required"
                            label="Agencia Implementadora"
                            item-text="nombreModalidad"
                            item-value="id"
                            return-object
                            :no-data-text="
                                modalidadesEjecucion != null
                                ? 'Seleccione una modalidad'
                                : 'No se han encontrado modalidades de ejecución'
                            "
                            menu-props="auto"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                        <v-text-field
                            v-model="datosCooperacion.monto"
                            dense
                            filled
                            :disabled="tipo===1"
                            class="required"
                            label="Monto"
                            item-text="nombreModalidad"
                            item-value="id"
                            return-object
                            :no-data-text="
                                modalidadesEjecucion != null
                                ? 'Seleccione una modalidad'
                                : 'No se han encontrado modalidades de ejecución'
                            "
                            menu-props="auto"
                        ></v-text-field>
                    </v-col>
                    

                </v-row>

            </v-card-text>
        </v-card>

        <v-row v-if="tipo===2">
            <v-col cols="12">
                <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right mt-2"
                    type="submit"
                    :elevation="0"
                    :disabled="!validForm"
                    :loading="btnRegistroLoading"
                >
                    {{btnRegistroText}}
                </v-btn>
            </v-col>
        </v-row>
            
            



        </v-form>

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
</div>
</div>

</template>

<script>

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";

import moment from "moment";
import { OBTENER_INSTITUCIONES } from "@/core/services/store/instituciones/institucion.module";
import { OBTENER_UNIDADES_EJECUTORAS_INSTITUCION } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import { OBTENER_ITEMS_CATALOGO, OBTENER_SUBITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { REGISTRAR_PROYECTO_CNSCOO_EXTERNO, OBTENER_PROYECTO_CNSCOO, ACTUALIZAR_PROYECTO_CNSCOO } from "@/core/services/store/proyectoscooperantes/proyectocooperantes.module";
import { OBTENER_AGENCIAS_MULTILATERALES } from "@/core/services/store/catalogos/agenciasmultilaterales/agenciamultilateral.module";

export default {
    name: 'SeccionInformacionGeneralProyectoCooperacion',
    components: {
        SnackAlert
    },
    props: ['id', 'cooperanteId', 'fuenteCooperanteId', 'agenciaId', 'tipoFuenteCooperanteId', 'accion', 'tipo'], //Tipo 1 segeplan (solo visualizar), tipo 2 externo (visualizar y editar)
    data(){
        return {
            skeletonLoading: false,
            validForm: false,
            btnRegistroText: "Registrar información",
            btnRegistroLoading: false,
            instituciones: [],
            institucionesLoading: false,
            unidadesEjecutorasLoading: false,
            unidadesEjecutoras: [],
            unidadEjecutoraSeleccionada:{},
            instrumentos: [],
            instrumentoSeleccionado: {},
            tiposInstrumentos: [],
            tiposInstrumentoSeleccionado: {},
            ddTiposInstrumentoLoading: false,
            cambiarFuenteCooperante: 1,
            cambiarFuenteCooperanteItems: [{id:1, text: 'No'}, {id:2, text: 'Si'}],
            programaConjunto: 1,
            programaConjuntoItems: [{id:1, text: 'No'}, {id:2, text: 'Si'}],
            tiposFuenteCooperante: [],
            tipoFuenteCooperanteSeleccionada: {},
            paisesFuente: [],
            fuentesBilaterales:[],
            fuenteBilateralSeleccionada: {},
            fuentesMultilateral: [],
            fuenteMultilateralSeleccionada: {},
            agencias: [],
            agenciaSeleccionada: {},
            tiposCooperacion: [],
            tipoCooperacionSeleccionada: {},
            modalidadesEjecucion: [],
            modalidadEjecucionSeleccionada: {},
            tiposAprobacion: [],
            tipoAprobacionSeleccionada: {},

            
            modalidadesEjecucionSel: 0,
            ingresaAPresupuestoItems: [{id:1, text: 'Si'}, {id:2, text: 'No'}],
            ingresaAPresupuestoSeleccionado: {
                id: 2, text: "No"
            },
            fechaSuscripcion: '',
            fechaInicio: '',
            fechaFinalizacion: '',
            datosItem: 
            {
                nombreProyecto: '',
                fechaSolicitudOpinionTecnica: '', 
                unidadesEjecutorasId: 0,
                tiposInstrumentoId: 0,
                tiposCooperacionId: 0,
                modalidadEjecucionId: 0,
                cambiarFuenteCooperante: 1,
                tiposFuenteCooperanteId: 0,
                tiposAprobacionDonacionId: 0,
                numeroDonaciones: '',
                ingresaAPresupuesto: 0,
                codigoFuenteCooperante: '',
                codigoDelProyecto: '',
                codigoInstitucion: '',
                fuentesMultilateralesId: null,
                fuentesBilateralesId: null,
                cooperantesCNSCId: this.cooperanteId,
                agenciasId: null,
                agenciaMultilateralId: null,         
            },
            agenciasMultilaterales: [],
            ddAgenciasMultiLoading: false,

            tipoFuenteUsuario: {},
            fuentesBilateralesId: 0,
            agenciasId: 0,

            datosCooperacion:{
                monto:0,
            },
            ddAgenciasLoading: false,
            ...validations
                
            

        }
    },

    methods: {

        //Iniciar el componente con sus datos iniciales
        prepare(proyectoId){
            this.resetItems();
           
            this.tipoFuenteUsuario.id = this.tipoFuenteCooperanteId; //1 es bilateral

            //Si es bilateral, traer las fuentes bilaterales
            if(this.tipoFuenteCooperanteId===1){
                this.obtenerFuentesBilaterales().then(()=>{

                    this.fuenteBilateralSeleccionada = this.fuentesBilaterales.find(item => item.id === this.fuenteCooperanteId);
                    //this.fuenteBilateralSeleccionada.id = this.fuenteCooperanteId;

                
                    this.obtenerAgenciasPorFuenteBilateral().then(()=>{
                        if(this.agenciaId!=0 && this.agenciasId != null){
                           //this.agenciaSeleccionada.id = this.agenciaId;
                            this.agenciaSeleccionada = this.agencias.find(item => item.id === this.agenciaId);
                        } else {
                            this.agenciaSeleccionada={};
                        }

                    });
                });
                

                //this.fuentesBilateralesId=this.tipoFuenteCooperanteId;

            }
            else if(this.tipoFuenteCooperanteId===2){
                this.obtenerFuentesMultilateral();
                this.fuenteMultilateralSeleccionada.id = this.fuentesMultilateral.find(item => item.id === this.fuenteCooperanteId);
                //this.fuentesMultilateralesId=this.tipoFuenteCooperanteId;
                this.obtenerAgenciasMultilaterales(this.fuentesMultilateralesId).then(()=>{
                    if(this.agenciaId!=0 && this.agenciasId != null){
                        //this.agenciaSeleccionada = this.agenciaId;
                        this.agenciaSeleccionada = this.agenciasMultilaterales.find(item => item.id === this.agenciaId);
                    } else {
                        this.agenciaMultilateralSeleccionada=this.agenciaId;
                    }
                })

            }
            //console.log(this.tipoFuenteCooperanteId)
           
            
            
               
                this.obtenerSectoresBeneficiados();
                this.obtenerTiposFuenteCooperante();
                
                this.obtenerModalidadesEjecucion();
                if(this.accion===2){
                    this.obtenerDatosItem(proyectoId);
                    this.btnRegistroText = "Actualizar información";
                } else {
                    this.btnRegistroText = "Guardar información"
                }
            
        },

        resetItems(){
            this.tipoFuenteCooperanteSeleccionada={};
            this.fuenteBilateralSeleccionada= {};
            this.agenciaSeleccionada={};
            this.fechaSuscripcion = "";
            this.fechaInicio = "";
            this.fechaFinalizacion= "";
            this.ingresaAPresupuestoSeleccionado= {
                id: 2, text: "No"
            };
            this.datosItem=
            {
                nombreProyecto: '',
                fechaSolicitudOpinionTecnica: '', 
                unidadesEjecutorasId: 0,
                tiposInstrumentoId: 0,
                tiposCooperacionId: 0,
                modalidadEjecucionId: 0,
                cambiarFuenteCooperante: 1,
                tiposFuenteCooperanteId: 0,
                tiposAprobacionDonacionId: 0,
                numeroDonaciones: '',
                ingresaAPresupuesto: 0,
                codigoFuenteCooperante: '',
                codigoDelProyecto: '',
                codigoInstitucion: '',
                fuentesMultilateralesId: null,
                fuentesBilateralesId: null,
                agenciasId: null,
                agenciaMultilateralId:null,
                cooperantesCNSCId: this.cooperanteId,         
            };

        },

        /////////////ESTOS DATOS SE DEBEN OBTENER DEL USUARIO LOGUEADO//////////////////////
        async obtenerTipoFuenteUsuario(){
           
            //this.obtenerAgenciasPorFuenteBilateral();
            //this.agenciasId=1;

        },


        configIngresaPresupuesto(){
            this.datosItem.codigoInstitucion = "";
            this.datosItem.codigoFuenteCooperante = "";
            this.datosItem.codigoDelProyecto = "";
        },


        //Sectores beneficiados
        obtenerSectoresBeneficiados() {
            this.sectoresBeneficiados = [];
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Sectores/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.sectoresBeneficiados = res.data;
                }
                })
                .catch(() => {
                this.sectoresBeneficiados = [];
                });
        
        },


        async obtenerDatosItem(idProyecto){
           
            this.datosItem = [];

            await this.$store
            .dispatch(OBTENER_PROYECTO_CNSCOO, {id: idProyecto})
            .then(res => {
            //console.log(res)
                if (res.status === 200) {
                    this.datosItem = res.data;

                    if(!this.datosItem.numeroDonaciones){
                        this.datosItem.numeroDonaciones = ''; 
                    }
                    
                    this.fechaSuscripcion = moment(this.datosItem.fechaSuscripcion, "YYYY-MM-DD").format("DD-MM-YYYY");
                    this.fechaInicio = moment(this.datosItem.fechaInicio, "YYYY-MM-DD").format("DD-MM-YYYY");
                    this.fechaFinalizacion = moment(this.datosItem.fechaFinalizacion, "YYYY-MM-DD").format("DD-MM-YYYY");

                    this.tipoCooperacionSeleccionada = this.tiposCooperacion.find(item => item.id ===this.datosItem.tiposCooperacionId);
                    this.modalidadEjecucionSeleccionada = this.modalidadesEjecucion.find(item => item.id ===this.datosItem.modalidadEjecucionCNSCId);

                    !this.datosItem.codigoFuenteCooperante ? this.datosItem.codigoFuenteCooperante = '' : this.datosItem.codigoFuenteCooperante = this.datosItem.codigoFuenteCooperante;
                    !this.datosItem.codigoDelProyecto ? this.datosItem.codigoDelProyecto = '' : this.datosItem.codigoDelProyecto = this.datosItem.codigoDelProyecto;
                    
                    
                    //Fuente cooperante bilateral
                    if(this.datosItem.tiposFuenteCooperanteId===1){
                       // console.log(this.fuentesBilaterales)
                        this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===1);
                        this.fuenteBilateralSeleccionada =  this.fuentesBilaterales.find(item => item.id === this.datosItem.fuentesBilateralesId); 
                        this.obtenerAgenciasPorFuenteBilateral()
                        .then(()=>{
                            this.agenciaSeleccionada = this.agencias.find(item => item.id === this.datosItem.agenciasId);
                        });
                        

                       // console.log(this.datosItem.fuentesBilateralesId) //
                    } else if(this.datosItem.tiposFuenteCooperanteId===2){
                        //console.log(this.datosItem.fuentesMultilateralesId)
                        this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===2);
                        this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(item => item.id === this.datosItem.fuentesMultilateralesId);

                        this.obtenerAgenciasMultilaterales(this.datosItem.fuentesMultilateralesId).then(()=>{
                            this.agenciaMultilateralSeleccionada = this.agenciasMultilaterales.find(item => item.id === this.datosItem.agenciaMultilateralId);
                        });

                    } else{
                        this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===3);
                    }



                    this.$emit('mostrar-registro');
                    
                    /* //Validar si viene el dato de ingresaAPresupuesto
                    if(this.datosItem.ingresaAPresupuesto){
                        this.ingresaAPresupuestoSeleccionado = this.ingresaAPresupuestoItems.find(item => item.id === this.datosItem.ingresaAPresupuesto);
                    }

                    //Institucion y unidad ejecutora seleccionadas
                    this.obtenerUnidadesEjecutorasPorInstitucion(this.datosItem.institucionesId).then(() =>{

                        this.unidadEjecutoraSeleccionada = this.unidadesEjecutoras.find(item => item.id === this.datosItem.unidadesEjecutorasId);
                    });

                    //Tipo de instrumento
                    this.instrumentoSeleccionado = this.instrumentos.find(item => item.id === this.datosItem.instrumentosId);
                    this.obtenerTiposInstrumentos().then(() =>{
                        this.tiposInstrumentoSeleccionado = this.tiposInstrumentos.find(item => item.id === this.datosItem.tiposInstrumentoId);
                    });



                    //Tipo de aprobacion
                    this.tipoAprobacionSeleccionada =  this.tiposAprobacionDonacion.find(item => item.id === this.datosItem.tiposAprobacionDonacionId); 

                    if(this.datosItem.fuentesBilateralesId){
                       // console.log("si")
                        this.tipoFuenteCooperanteSeleccionada = this.tiposFuenteCooperante.find(item => item.id ===1);
                        this.fuenteBilateralSeleccionada =  this.fuentesBilaterales.find(item => item.id === this.datosItem.fuentesBilateralesId); 
                        this.obtenerAgenciasPorFuenteBilateral()
                        .then(()=>{
                            this.agenciaSeleccionada = this.agencias.find(item => item.id === this.datosItem.agenciasId);
                        });
                        

                       // console.log(this.datosItem.fuentesBilateralesId) //
                    } else if(this.datosItem.fuentesMultilateralesId){
                        //console.log(this.datosItem.fuentesMultilateralesId)
                        this.tipoFuenteCooperanteSeleccionada.id = 2;
                        this.fuenteMultilateralSeleccionada = this.fuentesMultilateral.find(item => item.id === this.datosItem.fuentesMultilateralesId);

                    } else{
                        this.tipoFuenteCooperanteSeleccionada.id = 3;
                    } */
                }
                //this.ddTiposInstrumentoLoading = false;
                 setTimeout(this.skeletonLoading=false, 700);
                 
            })
            .catch(error => {
                console.log(error)
                 this.skeletonLoading=false;
                //this.datosItem = [];
            // this.ddTiposInstrumentoLoading = false;
            });
        },

        //Obtener las instituciones
        async obtenerInstituciones() {
             this.skeletonLoading=true;
             this.instituciones = [];
            this.institucionesLoading = true;

            await this.$store
                .dispatch(OBTENER_INSTITUCIONES, {estadoId: 1, nivelInstitucionId: 1, tipoInstitucionId: 1})
                .then(() => {
                    this.instituciones = this.$store.state.institucion.instituciones;
                
                    this.institucionesLoading = false;
                })
                .catch(() => {
                    this.institucionesLoading = false;
                });
        },

        //Obtener las unidades ejecutoras
         async obtenerUnidadesEjecutorasPorInstitucion(institucionesId) {
            this.unidadesEjecutorasLoading = true;
            this.unidadesEjecutoras = [];
            

            await this.$store
                .dispatch(OBTENER_UNIDADES_EJECUTORAS_INSTITUCION, institucionesId)
                .then(() => {
                this.unidadesEjecutoras = this.$store.state.unidadejecutora.unidadesEjecutoras;
                this.unidadesEjecutorasLoading = false;
                })
                .catch(() => {
                this.unidadesEjecutorasLoading = false;
                });
            },
    

        //Obtener los tipos de fuente cooperante
         obtenerTiposFuenteCooperante() {
            this.tiposFuenteCooperante = [];

            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposFuenteCooperante/all/1'})
                .then(res => {
                if (res.status === 200) {
                    this.tiposFuenteCooperante = res.data;
                }
                })
                .catch(() => {
                    this.tiposFuenteCooperante = [];
                });
            },

        //Tipos de cooperacion
        async obtenerTiposCooperacion() {
            this.tiposCooperacion = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposCooperacion/all/1'})
            .then(res => {
            if (res.status === 200) {
                this.tiposCooperacion = res.data;
            }
            })
            .catch(() => {
                this.tiposCooperacion = [];
            });
        },
        

        async obtenerInstrumentos() {
            this.instrumentos = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Instrumentos/all/1'})
            .then(res => {  
            
                if (res.status === 200) {
                
                    this.instrumentos = res.data;
                }
            })
            .catch(() => {
                this.instrumentos = [];
            });

        },


        async obtenerTiposInstrumentos() {
            this.ddTiposInstrumentoLoading = true;
            this.tiposInstrumentos = [];
           // console.log(this.instrumentoSeleccionado.id)
            await this.$store
            .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'TiposInstrumento/all/1', id: this.instrumentoSeleccionado.id})
            .then(res => {
          //  console.log(res)
                if (res.status === 200) {
                    this.tiposInstrumentos = res.data;
                }
                this.ddTiposInstrumentoLoading = false;
            })
            .catch(() => {
                this.tiposInstrumentos = [];
                this.ddTiposInstrumentoLoading = false;
            });

        },


        

        //Obtener las modalidades de ejecución
        async obtenerModalidadesEjecucion() {
            this.modalidadesEjecucion = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'ModalidadEjecucionCNSC/all/1'})
            .then(res => {
                if (res.status === 200) {
                this.modalidadesEjecucion = res.data;
                }
            })
            .catch(() => {
                this.modalidadesEjecucion = [];
            });
        },

         //Obtener las modalidades de ejecución
        async obtenerTiposAprobacionDonacion() {
            this.tiposAprobacionDonacion = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposAprobacionDonacion/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.tiposAprobacionDonacion = res.data;
                }
            })
            .catch(() => {
                this.tiposAprobacionDonacion = [];
            });
        },

        async obtenerFuentesBilaterales() {
            this.fuentesBilaterales = [];
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuentesBilaterales/all/1/0'})
            .then(res => {
            if (res.status === 200) {
                this.fuentesBilaterales = res.data;
            }
            })
            .catch(() => {
            this.fuentesBilaterales = [];
            });
        },

        async obtenerAgenciasPorFuenteBilateral() {
            this.agencias = [];
            this.ddAgenciasLoading=true;
            await this.$store
            .dispatch(OBTENER_SUBITEMS_CATALOGO, {endpoint: 'Agencias/all/1', id: this.fuenteBilateralSeleccionada.id})
            .then(res => {
                if (res.status === 200) {
                    
                    this.agencias = res.data;
                }
                this.ddAgenciasLoading=false;
            })
            .catch(() => {
                this.ddAgenciasLoading=false;
                this.agencias = [];
            });
        },


        async obtenerFuentesMultilateral() {
        this.fuentesMultilateral = [];
        await this.$store.dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'FuenteMultiLateral/all/1'})
            .then(res => {
            if (res.status === 200) {
                this.fuentesMultilateral = res.data;
            }
            })
            .catch(() => {
                this.fuentesMultilateral = [];
            });
        },

        // Obtener agencias Multilaterales
        async obtenerAgenciasMultilaterales(fuenteMultilateralId) {
            this.agenciasMultilaterales = [];
            this.ddAgenciasMultiLoading = true;

            await this.$store
            .dispatch(OBTENER_AGENCIAS_MULTILATERALES, {estadoId:1, fuentesMultilateralesId: fuenteMultilateralId})
            .then(res => {
                if(res.status===200){

                    this.agenciasMultilaterales = res.data;
                }
            this.ddAgenciasMultiLoading = false;
            })
            .catch(() => {
                //console.log(error)
                this.ddAgenciasMultiLoading = false;
            });
        },


        configurarFuentes(){
        if(this.tipoFuenteCooperanteSeleccionada.id === 1){
            this.fuenteMultilateralSeleccionada={}
        } else if(this.tipoFuenteCooperanteSeleccionada.id === 2){
            this.fuenteBilateralSeleccionada ={};
        }
        else if(this.tipoFuenteCooperanteSeleccionada.id == 3){
            this.fuenteBilateralSeleccionada= {};
            this.fuenteMultilateralSeleccionada={};
        }
    },

      

        //Registrar informacion general
        async registrarItem(){
            this.btnRegistroLoading=true;
            this.datosItem.tiposInstrumentoId = this.tiposInstrumentoSeleccionado.id;
            this.datosItem.cooperantesCNSCId = this.cooperanteId;
            
            //this.datosItem.tiposCooperacionId = this.tipoCooperacionSeleccionada.id;

            this.datosItem.fechaSuscripcion = moment(this.fechaSuscripcion, "DD/MM/YYYY").format("YYYY-MM-DD");
            this.datosItem.fechaInicio = moment(this.fechaInicio, "DD/MM/YYYY").format("YYYY-MM-DD");
            this.datosItem.fechaFinalizacion = moment(this.fechaFinalizacion, "DD/MM/YYYY").format("YYYY-MM-DD");

            this.datosItem.modalidadEjecucionCNSCId = this.modalidadEjecucionSeleccionada.id;
            this.datosItem.unidadesEjecutorasId = this.unidadEjecutoraSeleccionada.id;
            
            this.datosItem.tiposAprobacionDonacionId = this.tipoAprobacionSeleccionada.id;
            this.datosItem.tiposFuenteCooperanteId = this.tipoFuenteUsuario.id;
            //this.datosItem.agenciasId = this.agenciaSeleccionada.id;
            this.datosItem.fuentesBilateralesId = this.fuenteBilateralSeleccionada.id;
            this.datosItem.fuentesMultilateralesId = this.fuenteMultilateralSeleccionada.id;
            this.datosItem.usuarioCreacion = "a";
            this.datosItem.estadosId = 1;
            this.ingresaAPresupuestoSeleccionado.id === 2 ? this.datosItem.ingresaAPresupuesto = 2 : this.datosItem.ingresaAPresupuesto = 1;

          /*   if(this.agenciaSeleccionada.id === 0 || !this.agenciaSeleccionada){
                this.datosItem.agenciasId = null;
            }

 */
            //Verficar si la fuente cooperante es bilateral
            //Verficar si la fuente cooperante es bilateral
            //console.log(this.agenciaSeleccionada)
            if(this.datosItem.tiposFuenteCooperanteId ===1){
                //Si es bilateral, poner nulo el fuenteMultilateral
                this.datosItem.fuentesMultilateralesId = null;

                
                if(!this.agenciaSeleccionada || this.agenciaSeleccionada.id === 0){ 
                    
                    this.datosItem.agenciasId = null;
                } else {
                    this.datosItem.agenciasId = this.agenciaSeleccionada.id;
                }

                //console.log(this.datosItem.agenciasId);


            } else if(this.datosItem.tiposFuenteCooperanteId ===2){
                //Si es multilateral poner nulos fuentesBilateralesId y agencias Id
                this.datosItem.fuentesBilateralesId = null;
                this.datosItem.agenciasId = null;

                if(!this.agenciaMultilateralSeleccionada || this.agenciaMultilateralSeleccionada.id === 0){
                    this.datosItem.agenciaMultilateralId = null;
                } else {
                    this.datosItem.agenciaMultilateralId = this.agenciaMultilateralSeleccionada.id;
                }

            } else if(this.datosItem.tiposFuenteCooperanteId ===3){ 
                this.datosItem.fuentesMultilateralesId = null;
                this.datosItem.fuentesBilateralesId = null;
                this.datosItem.agenciasId = null;
                this.datosItem.agenciaMultilateralId = null;
            }

            this.datosItem.fechaSolicitudOpinionTecnica = moment(this.fechaSolicitudOpinionTecnica, "DD/MM/YYYY").format("YYYY-MM-DD");

            this.datosItem.id = 0;

            let dispatch = REGISTRAR_PROYECTO_CNSCOO_EXTERNO;
            if(this.accion===2){
                dispatch = ACTUALIZAR_PROYECTO_CNSCOO;
                this.datosItem.id = this.id;
            }

           
            await this.$store
            .dispatch(dispatch, { datos: this.datosItem, seccion: 'InformacionGeneral'})
            .then(res => {
                //console.log(res)
                if(res.status===200){
                // this.obtnenerItems();
                // this.dialog=false;
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                    this.$emit('get-items');
                    if(this.accion===1){
                        this.id=res.data;
                    }
                    this.accion = 2;
                    
                    this.$emit('actualizar-accion', 2);
                    this.$emit('actualizar-id', this.id);
                    this.btnRegistroText = "Actualizar información";
                //  this.switchItemEstado = true;
                    this.btnRegistroLoading=false;
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnRegistroLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnRegistroLoading=false;
            });
            
        },

    },


    created() {
        this.prepare(this.id);
     
        //this.skeletonLoading=true;
        /*this.obtenerInstituciones();
        
        
        this.obtenerFuentesBilaterales().then(() => {
            this.obtenerAgenciasPorFuenteBilateral();
            this.obtenerTiposCooperacion().then(()=> {
                this.obtenerModalidadesEjecucion().then(()=> {
                    this.obtenerTiposAprobacionDonacion().then(()=> {
                        this.obtenerInstrumentos().then(() =>{
                            this.obtenerDatosItem(this.id);
                        });
                    });
                })
            });
        });
*/        
    },
}
</script>