<template>

<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>
<div v-if="!skeletonLoading">
    <!--inicio:: condiciones de beneficiarios -->
    <v-row>
        <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
            <v-subheader class="text-h5 black--text">
                Detalle de los beneficiarios del proyecto
            </v-subheader>
        </v-col>
    </v-row>

    <v-card class="mt-6" outlined>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                    <v-subheader class="text-h6 black--text">
                        Beneficiarios directos
                    </v-subheader>
                </v-col>
            </v-row>

            <v-form 
                ref="formTipoBeneficiarios"
                v-on:submit.prevent="actualizarTipoBeneficiarios"
                v-model="validFormTipoBeneficiarios"
                >
                <v-row>

                    <v-col cols="12" md="7" sm="12" class="pt-6 pb-0">
                        <v-select
                            v-model="beneficiarioDirectoSel"
                            :items="beneficiariosDirectosItems"
                            :loading="false"
                            dense
                            filled
                            class="required"
                            return-object   
                            label="¿El proyecto cuenta con beneficiarios directos?"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('cuenta con beneficiarios directos'),
                            ]"
                        ></v-select>
                    </v-col>
                    
                    <v-col cols="12" md="5" sm="12" class="pt-6 pb-0">
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right"
                            type="submit"
                            medium
                            :elevation="0"
                            :disabled="!validFormTipoBeneficiarios || beneficiarioDirectoSel.id === 0"
                            :loading="btnRegistroTipoBeneficiariosLoading"
                        >
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <v-row>

                <v-col cols="12" class="pt-0">
                    <v-divider class="mt-0 pt-0"></v-divider>
                </v-col>

                <v-col cols="12" md="12" sm="12" class="pt-6 pb-0" v-if="habilitarRegistroBeneficiarios">
                    <v-form 
                        ref="formBeneficiarios"
                        v-on:submit.prevent="registrarBeneficiario"
                        v-model="validFormBeneficiarios"
                        >



                        <v-row class="mt-4">

                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosBeneficiario.departamentosId"
                                    :items="departamentos"
                                    :loading="departamentosLoading"
                                    dense
                                    class="required"
                                    filled
                                    label="Departamento"
                                    item-text="departamento"
                                    item-value="id"
                                    @change="obtenerMunicipiosPorDepartamento(1, datosBeneficiario.departamentosId)"
                                    :no-data-text="
                                        departamentos.length > 0
                                        ? 'Seleccione un departamento'
                                        : 'No se han encontradodepartamentos'
                                    "
                                    menu-props="auto"
                                    :rules="[
                                        selectRequired('departamento')
                                    ]"
                                    >
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-select
                                    :clearable="true"
                                    v-model="datosBeneficiario.municipiosId"
                                    :items="municipios"
                                    :loading="municipiosLoading"
                                    dense
                                    class=""
                                    filled
                                    label="Municipio"
                                    item-text="municipio"
                                    item-value="id"
                                    :no-data-text="
                                        municipios.length > 0
                                        ? 'Seleccione un municipio'
                                        : 'No se han encontrado municipios'
                                    "
                                    menu-props="auto"
                                    >
                                </v-select>
                            </v-col>

                        </v-row> 

                        <v-row>

                            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-select
                                    v-model="generoSeleccionado"
                                    :items="generos"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"
                                    return-object   
                                    :rules="[
                                        selectRequired('género beneficiado')
                                    ]"
                                    label="Género Beneficiado"
                                    item-text="genero"
                                    item-value="id"
                                    menu-props="auto"
                                ></v-select>
                            </v-col>


                            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosBeneficiario.areasBeneficiadasId"
                                    :items="areasBeneficiadas"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"   
                                    :rules="[
                                        selectRequired('área beneficiada')
                                    ]"
                                    label="Área Beneficiada"
                                    item-text="nombreArea"
                                    item-value="id"
                                    menu-props="auto"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosBeneficiario.etniasId"
                                    :items="etnias"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"
                                      
                                    :rules="[
                                        selectRequired('etnia')
                                    ]"
                                    label="Etnia"
                                    item-text="nombreEtnia"
                                    item-value="id"
                                    menu-props="auto"
                                ></v-select>
                            </v-col>


                            <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosBeneficiario.rangosEdadId"
                                    :items="rangosEdad"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"
                                      
                                    :rules="[
                                        selectRequired('rango de edad')
                                    ]"
                                    label="Rango de edad"
                                    item-text="rango"
                                    item-value="id"
                                    menu-props="auto"
                                ></v-select>
                            </v-col>


                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class=""
                                    color="blue-grey lighten-2"
                                    hint="Detalle el número de beneficiarios para este registro"
                                    v-model="datosBeneficiario.cantidadBeneficiarios"
                                    label="Número de beneficiarios"
                                    :rules="[
                                        datosBeneficiario.cantidadBeneficiarios.length > 0 ? onlyInteger('número de beneficiarios') : true,
                                        datosBeneficiario.cantidadBeneficiarios.length > 0 ? minNumber('número de beneficiarios', 0) : true
                                    ]"
                                    maxlength="11"
                                    v-mask="'###########'"
                                    ></v-text-field>
                            </v-col>


                            <v-col cols="12" md="4" sm="6" xs="12" class="pt-0 pb-0">
                                <v-text-field
                                    dense
                                    filled
                                    autocomplete="off"
                                    class=""
                                    color="blue-grey lighten-2"
                                    v-model="datosBeneficiario.monto"
                                    label="Monto quetzales"
                                    :rules="[
                                        datosBeneficiario.monto.length > 0 ? decimals10('monto') : true
                                    ]"
                                    suffix="GTQ"
                                    maxlength="26"
                                ></v-text-field>
                            </v-col>


                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" sm="12" class="pt-0">
                                    
                                <v-btn
                                    color="light-blue-502"
                                    class="white--text mb-2 float-right"
                                    type="submit"
                                    :elevation="0"
                                    :disabled="!validFormBeneficiarios || generoSeleccionado.id ===0"
                                    :loading="btnRegistroBeneficiarioLoading"
                                >
                                    Registrar
                                </v-btn>
                            </v-col>


                        </v-row>
                    </v-form>
                </v-col>

                 <!--inicio:: tabla beneficiarios -->
                <v-col cols="12" md="12" sm="12" class="pt-6" v-if="habilitarRegistroBeneficiarios">
                    <v-data-table
                        class="elevation-0"
                        :headers="headersBeneficiarios"
                        :items="beneficiarios"
                        :loading="tableBeneficiariosLoading"
                        hide-default-footer
                        :items-per-page="20"
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            'items-per-page-text': 'Registros por página',
                            pageText: '{0}-{1} de {2}'
                        }"
                        >

                            <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.municipio ? item.municipio + `, ` : ``}}{{ item.departamento }}</td>
                                <td>{{ item.genero }}</td>
                                <td>{{ item.nombreArea }}</td>
                                <td>{{ item.nombreEtnia }}</td>
                                <td>{{ item.rango }}</td>
                                <td>{{ item.cantidadBeneficiarios }}</td>
                                <td>{{ item.monto ? parseFloat(item.monto).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) : ``}}</td>
                                <td>
                                    <v-btn
                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                        small
                                        depressed
                                        color="blue-grey lighten-5"
                                        @click="eliminarDistribucionMontoSuscrito(item.nombreTipoDatoFinanciero)"
                                        >
                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                    </v-btn>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:no-data>
                            <p class="text-h7">
                                <v-icon left>mdi-alert</v-icon> No existe información registrada de distribución de montos
                            </p>
                        </template>

                    </v-data-table>

                </v-col>
                <!--fin:: tabla beneficiarios -->

            </v-row>
        </v-card-text>
    </v-card>
    <!--fin:: condiciones de beneficiarios -->

    <!--inicio:: condiciones de instituciones beneficiarias -->
    <v-card class="mt-6" outlined>
        <v-card-text>
            <v-row>
                <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                    <v-subheader class="text-h6 black--text">
                        Detalle de las instituciones beneficiarias
                    </v-subheader>
            
                    
                </v-col>

            </v-row>

            <v-form 
                ref="formInstitucionesBeneficiarias"
                v-on:submit.prevent="actualizarCuentaInstitucionesBeneficiarias"
                v-model="validFormCuentaInstituciones"
                >
                <v-row>

                    <v-col cols="12" md="7" sm="12" class="pt-6 pb-0">
                        <v-select
                            v-model="cuentaInstitucionesBeneficiariasSel"
                            :items="institucionesBeneficiariasItems"
                            :loading="false"
                            dense
                            filled
                            class="required"
                            return-object   
                            label="¿El proyecto cuenta con instituciones beneficiarias?"
                            item-text="text"
                            item-value="id"
                            menu-props="auto"
                            :rules="[
                                selectRequired('cuenta con instituciones beneficiarias'),
                            ]"
                        ></v-select>
                    </v-col>
                    
                    <v-col cols="12" md="5" sm="12" class="pt-6 pb-0">
                        <v-btn
                            color="light-blue-502"
                            class="white--text mb-2 float-right"
                            type="submit"
                            medium
                            :elevation="0"
                            :disabled="!validFormCuentaInstituciones || cuentaInstitucionesBeneficiariasSel.id===0"
                            :loading="btnRegistroCuentaInstitucionesLoading"
                        >
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>

            <v-row>
                <v-col cols="12" md="12">
                    <v-divider class="mt-0 pt-0"></v-divider>
                </v-col>
            </v-row>

            <v-row v-if="habilitarRegistroInstituciones" class="mt-4">

                <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                    <v-form 
                        ref="formInstitucionesBeneficiarias"
                        v-on:submit.prevent="registrarInstitucionBeneficiaria"
                        v-model="validFormBenefifiariosInstitucion"
                        >
                        <v-row>

                            <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                <v-autocomplete
                                    v-model="datosInstitucionBeneficiaria.ENTIDAD_ID"
                                    :items="instituciones"
                                    :loading="ddInstitucionesLoading"
                                    dense
                                    filled
                                    class="required"
                                    :rules="[
                                        selectRequired('institución beneficiada')
                                    ]"
                                    label="Institución"
                                    item-text="nombreInstitucion"
                                    item-value="id"
                                    menu-props="auto"
                                ></v-autocomplete>
                            </v-col>


                            <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosInstitucionBeneficiaria.tiposIngresoId"
                                    :items="tiposIngreso"
                                    :loading="false"
                                    dense
                                    filled
                                    class="required"
                                    :rules="[
                                        selectRequired('tipo de ingreso')
                                    ]"
                                    label="Rol (Tipo de ingreso)"
                                    item-text="nombreTipoIngreso"
                                    item-value="id"
                                    menu-props="auto"
                                ></v-select>
                            </v-col>

                        </v-row>


                        <v-row>
                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosInstitucionBeneficiaria.departamentosId"
                                    :items="departamentos"
                                    :loading="departamentosActividadLoading"
                                    dense
                                    class="required"
                                    filled
                                    label="Departamento"
                                    item-text="departamento"
                                    item-value="id"
                                    @change="obtenerMunicipiosPorDepartamento(2, datosInstitucionBeneficiaria.departamentosId)"
                                    :no-data-text="
                                        departamentos != null
                                        ? 'Seleccione un departamento'
                                        : 'No se han encontradodepartamentos'
                                    "
                                    menu-props="auto"
                                    >
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                                <v-select
                                    v-model="datosInstitucionBeneficiaria.municipiosId"
                                    :items="municipiosInstitucion"
                                    :loading="municipiosInstitucionLoading"
                                    :clearable="true"
                                    dense
                                    class=""
                                    filled
                                    label="Municipio"
                                    item-text="municipio"
                                    item-value="id"
                                    :no-data-text="
                                        municipios != null
                                        ? 'Seleccione un municipio'
                                        : 'No se han encontrado municipios'
                                    "
                                    menu-props="auto"
                                    >
                                </v-select>
                            </v-col>
                        </v-row>


                        <v-row>

                            <v-col cols="12" md="12" sm="12" class="pt-0">
                                    
                                <v-btn
                                    color="light-blue-502"
                                    class="white--text mb-2 float-right"
                                    type="submit"
                                    :elevation="0"
                                    :disabled="!validFormBenefifiariosInstitucion"
                                    :loading="btnRegistroBeneficiarioInstitucionLoading"
                                >
                                    Agregar institución
                                </v-btn>
                            </v-col>


                        </v-row>
                    </v-form>
                </v-col>

                 <!--inicio:: tabla beneficiarios -->
                <v-col cols="12" md="12" sm="12" class="pt-6" v-if="habilitarRegistroInstituciones">
                    <v-data-table
                        class="elevation-0"
                        :headers="headersInstitucionesBeneficiarias"
                        :items="institucionesBeneficiarias"
                        :loading="tableBeneficiariosLoading"
                        hide-default-footer
                        :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            'items-per-page-text': 'Registros por página',
                            pageText: '{0}-{1} de {2}'
                        }"
                        >

                            <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.nombreInstitucion }}</td>
                                <td>{{ item.nombreTipoIngreso }}</td>
                                <td>{{ item.departamento }}</td>
                                <td>{{ item.municipio }}</td>
                                <td>
                                    <v-btn
                                        class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                        small
                                        depressed
                                        :disabled="btnEliminarInstitucionDisabled"
                                        :loading="btnEliminarInstitucionDisabled"
                                        color="blue-grey lighten-5"
                                        @click="eliminarInstitucionBeneficiaria(item.id)"
                                        >
                                        <v-icon left>mdi-delete</v-icon> Eliminar
                                    </v-btn>
                                </td>
                            </tr>
                        </template>

                        <template v-slot:no-data>
                            <p class="text-h7">
                                <v-icon left>mdi-alert</v-icon> No existe información registrada de distribución de montos
                            </p>
                        </template>

                    </v-data-table>

                </v-col>
                <!--fin:: tabla beneficiarios -->

            </v-row>
        </v-card-text>
    </v-card>
    <!--fin:: condiciones de instituciones beneficiarias -->

    </div>


    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->
</div>
</template>


<script>
import DialogLoader from "@/view/content/DialogLoader";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import validations from "@/core/untils/validations.js";
//import { OBTENER_DATOS_SECCIONES_PROYECTO } from "@/core/services/store/proyectoscns/proyectocns.module";
import {OBTENER_PROYECTO_CNSCOO} from "@/core/services/store/proyectoscooperantes/proyectocooperantes.module";

import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import { ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNSC, ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNSC, OBTENER_BENEFICIARIOS_CNSC, REGISTRAR_BENEFICIARIO_CNSC } from "@/core/services/store/proyectoscooperantes/beneficiarioscooperante/beneficiariocnsc.module";
import { OBTENER_BENEFICIARIOS_INSTITUCION, REGISTRAR_BENEFICIARIO_INSTITUCION, ELIMINAR_INSTITUCION_BENEFICIARIA_CNS } from "@/core/services/store/proyectoscns/beneficiarios/beneficiarioinstitucion.module";
//import { OBTENER_INSTITUCIONES } from "@/core/services/store/instituciones/institucion.module";
import { OBTENER_DEPARTAMENTOS_PAIS } from "@/core/services/store/catalogos/departamentos/departamento.module";
import { OBTENER_MUNICIPIOS_DEPARTAMENTO } from "@/core/services/store/catalogos/municipios/municipio.module";

export default {
    name: 'SeccionBeneficiariosProyectoCNSC',
    props: ['id', 'tipo'],
    data(){
        return{
            cardDatosLoading: false,
            skeletonLoading: false,
            ddInstitucionesLoading: false,
            validFormTipoBeneficiarios: false,
            validFormCuentaInstituciones: false,
            btnRegistroTipoBeneficiariosLoading: false,
            tableBeneficiariosLoading: false,
            tableBeneficiariosInstitucionLoading: false,
            dialogLoaderVisible:false,
            dialogLoaderText: '',
            validFormBeneficiarios: false,
            validFormBenefifiariosInstitucion: false,
            btnRegistroBeneficiarioLoading: false,
            btnRegistroBeneficiarioInstitucionLoading: false,
            datosCuentaInstitucionesBeneficiarias: {
                id: this.id,
                cuentaInstitucionesBeneficiarias: 0,
                usuarioActualizacoin: 'admin'

            },
            btnRegistroCuentaInstitucionesLoading: false,

            beneficiariosDirectosItems: [{id: 0, text: 'Seleccione'}, {id: 1, text: 'Si'}, {id: 2, text: 'No'} ],
            beneficiarioDirectoSel:{},
            cuentaInstitucionesBeneficiariasSel: {},
            institucionesBeneficiariasItems: [{id: 0, text: 'Seleccione'}, {id: 1, text: 'Si'}, {id: 2, text: 'No'} ],
            generos: [],
            generoSeleccionado: {},
            areasBeneficiadas: [],
            etnias: [],
            rangosEdad: [],
            instituciones: [],
            tiposIngreso:[],
            beneficiarios: [],
            institucionesBeneficiarias: [],
            datosTipoBeneficiario:{
                id:0,
                beneficiariosDirectos: 0,
                usuarioActualizacoin: "admin",
            },
            datosItem: {
                sectoresBeneficiadosId: 0,
            },

            datosBeneficiario: {
                proyectoCNSCId: this.id,
                monto: "",
                departamentosId: 0,
                municipiosId: 0,
                genero: "",
                areasBeneficiadasId: 0,
                etniasId: 0,
                rangosEdadId: 0,
                cantidadBeneficiarios: "",
                estadosId: 1,
                usuarioCreacion: "admin",
            },

            datosInstitucionBeneficiaria:{
                tipoProyecto: 2,
                proyectoCNSCId: this.id,
                departamentosId: null,
                municipiosId: null,
                ENTIDAD_ID:0,
                tiposIngresoId:0,
                estadosId: 1,
                usuarioCreacion: 'admin'
            },
            habilitarRegistroInstituciones:false,
            habilitarRegistroBeneficiarios: false,
            departamentosLoading: false,
            departamentos: [],
            municipiosLoading: false,
            municipios: [],
            municipiosInstitucionLoading: false,
            municipiosInstitucion: [],
            btnEliminarInstitucionDisabled: false,
            btnEliminarBeneficiarioDisabled: false,
            ...validations
        }
    },

    components: {
        DialogLoader,
        SnackAlert
    },

    methods: {


        resetItems(tipo){
            if(tipo===2){
                this.skeletonLoading=true;
            }
            this.habilitarRegistroInstituciones=false;
            this.habilitarRegistroBeneficiarios= false;
            this.beneficiarioDirectoSel={
                id:0, 
                text: 'Seleccione'
            };
            this.cuentaInstitucionesBeneficiariasSel={
                id:0, 
                text: 'Seleccione'
            };
            this.municipios=[];
            this.resetForm();
            this.resetFormInstitucion();
            
        },


        resetForm(){
            this.generoSeleccionado={};

            this.datosBeneficiario= {
                proyectoCNSCId: this.id,
                monto: "",
                departamentosId: 0,
                municipiosId: 0,
                genero: "",
                areasBeneficiadasId: 0,
                etniasId: 0,
                rangosEdadId: 0,
                cantidadBeneficiarios: "",
                estadosId: 1,
                usuarioCreacion: "admin",
            };
        },
        resetFormInstitucion(){

            this.datosInstitucionBeneficiaria = {
                tipoProyecto: 2,
                proyectoCNSCId: this.id,
                ENTIDAD_ID:0,
                departamentosId: null,
                municipiosId: null,
                tiposIngresoId:0,
                estadosId: 1,
                usuarioCreacion: 'admin'
            }
        },


        //Obtener los departamentos
        async obtenerDepartamentos() {
            this.departamentosLoading=true;
            
            this.departamentos = [];
           
            await this.$store
            .dispatch(OBTENER_DEPARTAMENTOS_PAIS, 94) //94 representa al país 94 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                    this.departamentos = res.data;
                }
                this.departamentosLoading=false;
           
            })
            .catch(() => {
                this.departamentos = [];
                this.departamentosLoading=false;
               
            });
        
        },

        //Obtener municipios por departamento
        async obtenerMunicipiosPorDepartamento(tipo, departamentosId){
            

            if(tipo===1){
                this.municipiosLoading=true;
                this.datosBeneficiario.municipiosId=null;
                this.municipios = [];
            } else {
                this.municipiosInstitucion = [];
                this.municipiosInstitucionLoading = true;
                this.datosInstitucionBeneficiaria.municipiosId = null;
            }

            
            

            await this.$store
            .dispatch(OBTENER_MUNICIPIOS_DEPARTAMENTO, departamentosId) //1 representa al país 1 (Guatemala)
            .then(res => {
                if (res.status === 200) {
                
                    if(tipo===1){
                        this.municipios = res.data;
                    } else {
                        this.municipiosInstitucion = res.data;
                    }

                }

                this.municipiosInstitucionLoading = false;
                this.municipiosLoading = false;
            })
            .catch(() => {

                if(tipo===1){
                    this.municipios = [];
                } else {
                    this.municipiosInstitucion = [];
                }
                this.municipiosInstitucionLoading = false;
                this.municipiosLoading = false;
                
            });
        },



        //Obtener los generos
        async obtenerGeneros(){
            this.generos = [{id: 0, genero: 'Seleccione', codigo: "N/A"}, {id: 1, genero: 'Femenino', codigo: "F"}, {id: 2, genero: 'Masculino', codigo: "M"}, {id: 3, genero: 'Otros', codigo: "O"}];


            
            /* this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Generos'})
                .then(res => {
                    if (res.status === 200) {
                        this.generos = res.data;
                        this.cardDatosLoading = false;
                    }
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                }); */
        },




        //Obtener areas beneficiadas
        async obtenerAreasBeneficiadas(){
            this.areasBeneficiadas = [];

            
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'AreasBeneficiadas/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.areasBeneficiadas = res.data;
                        this.cardDatosLoading = false;
                    }
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                });
        },


        //Obtener etnias
        async obtenerEtnias(){
            this.etnias = [];

            
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Etnias/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.etnias = res.data;
                        this.cardDatosLoading = false;
                    }
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                });
        },



        //Obtener rangos de edad
        async obtenerRangosEdad(){
            this.rangosEdad = [];

            
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'RangosEdad/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.rangosEdad = res.data;
                        this.cardDatosLoading = false;
                    }
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                });
        },


         /// Obtener instituciones
        /*async obtenerInstituciones() {
        this.instituciones = [];
        this.tableLoading = true;

        this.$store
            .dispatch(OBTENER_INSTITUCIONES, {estadoId: 1, nivelInstitucionId: 0, tipoInstitucionId: 1})
            .then(() => {
            this.instituciones = this.$store.state.institucion.instituciones;
           
            this.tableLoading = false;
            })
            .catch(() => {
            this.tableLoading = false;
            });
        },*/

        async obtenerInstituciones() {

            this.instituciones = [];
            this.ddInstitucionesLoading = true;

            this.instituciones = [];
            await this.$store                                                       
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
                .then(res => {
                if(res.status==200){

                    this.instituciones = res.data;
                }
                this.ddInstitucionesLoading=false;
                
                })
                .catch(() => {
                this.ddInstitucionesLoading = false;
                this.ddInstitucionesLoading=false;
                this.instituciones=[];
                });
            },


        //Obtener tipos de ingreso
        async obtenerTiposIngreso(){
            this.tiposIngreso = [];

            
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'TiposIngreso/all/1'})
                .then(res => {
                    if (res.status === 200) {
                        this.tiposIngreso = res.data;
                        this.cardDatosLoading = false;
                    }
                })
                .catch(() => {
                    this.cardDatosLoading = false;
                });
        },



        //Obtener la información de cobertura
        async obtenerDatosSeccion(proyectoId){

            this.datosItem = {};
            this.cardDatosLoading = true;
         
            this.$store
                .dispatch(OBTENER_PROYECTO_CNSCOO, {id: proyectoId})
                .then(res => {
                    if(res.status===200){
                        
                        this.datosItem = res.data;
                        if(this.datosItem.cuentaInstitucionesBeneficiarias){
                            this.cuentaInstitucionesBeneficiariasSel = this.institucionesBeneficiariasItems.find(item => item.id ===this.datosItem.cuentaInstitucionesBeneficiarias);
                            if(this.datosItem.cuentaInstitucionesBeneficiarias===1){
                                this.habilitarRegistroInstituciones = true;
                            }
                        } else{
                            this.cuentaInstitucionesBeneficiariasSel = {
                                id: 0, text: 'Seleccione'
                            }
                        }

                      //  console.log(this.datosItem.beneficiariosDirectos)

                        if(this.datosItem.cuentaBeneficiariosDirectos){
                            this.beneficiarioDirectoSel = this.beneficiariosDirectosItems.find(item => item.id ===this.datosItem.cuentaBeneficiariosDirectos);
                            if(this.datosItem.cuentaBeneficiariosDirectos===1){
                                this.habilitarRegistroBeneficiarios = true;
                            }
                        } else{
                            this.beneficiarioDirectoSel = {
                                id: 0, text: 'Seleccione'
                            }
                        }


                        
                    }
                    this.cardDatosLoading = false;
                    this.skeletonLoading=false;
                    this.condicionDesembolsos.id = this.datosItem.condicionesDesembolsos;
                })
                .catch(() => {
                    this.skeletonLoading=false;
                    this.cardDatosLoading = false;
                });

        },


        //Obtener los beneficiarios
        async obtenerBeneficiarios(proyectoId){
            this.skeletonLoading=true;
            this.beneficiarios = [];
            this.tableBeneficiariosLoading = true;
         
            this.$store
                .dispatch(OBTENER_BENEFICIARIOS_CNSC, {estadoId: 1, tipoProyecto: 2, proyectoId: proyectoId})
                .then(res => {
                    if(res.status === 200){
                        this.beneficiarios = res.data;
                    }
                    this.tableBeneficiariosLoading = false;
                    this.skeletonLoading=false;
                })
                .catch(() => {
                    this.tableBeneficiariosLoading = false;
                    this.skeletonLoading=false;
                });
        },


        //Obtener los beneficiarios de instituciones
        async obtenerBeneficiariosInstitucion(proyectoId){
            
            this.institucionesBeneficiarias = [];
            this.tableBeneficiariosInstitucionLoading = true;
         
            this.$store
                .dispatch(OBTENER_BENEFICIARIOS_INSTITUCION, {estadoId: 1, tipoProyecto: 2, proyectoId: proyectoId})
                .then(res => {
                    if(res.status===200){

                        this.institucionesBeneficiarias = res.data;
                    }
                    this.tableBeneficiariosInstitucionLoading = false;
                    
                })
                .catch(() => {
                    this.institucionesBeneficiarias = [];
                    this.tableBeneficiariosInstitucionLoading = false;
                });
        },


        //Actualizar el tipo de beneficiarios con los que cuenta el proyecto
        async actualizarTipoBeneficiarios(){
            this.btnRegistroTipoBeneficiariosLoading = true;

            this.datosTipoBeneficiario.id = this.id;
            this.datosTipoBeneficiario.beneficiariosDirectos = this.beneficiarioDirectoSel.id;
            this.datosTipoBeneficiario.usuarioActualizacoin = 'admin';

            await this.$store
            .dispatch(ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNSC, {datos: this.datosTipoBeneficiario})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);   
                    this.beneficiarioDirectoSel.id===1 ? this.habilitarRegistroBeneficiarios = true : this.habilitarRegistroBeneficiarios = false;
                             
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroTipoBeneficiariosLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                this.btnRegistroTipoBeneficiariosLoading=false;
            });
        },


        //Actualizar el campo "El proyecto cuenta con instituciones beneficiarias"
        async actualizarCuentaInstitucionesBeneficiarias(){
            
            this.btnRegistroCuentaInstitucionesLoading = true;

            this.datosCuentaInstitucionesBeneficiarias.id = this.id;
            this.datosCuentaInstitucionesBeneficiarias.cuentaInstitucionesBeneficiarias = this.cuentaInstitucionesBeneficiariasSel.id;
            this.datosCuentaInstitucionesBeneficiarias.usuarioActualizacoin = 'admin';

            await this.$store
            .dispatch(ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNSC, {datos: this.datosCuentaInstitucionesBeneficiarias})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);      
                    this.cuentaInstitucionesBeneficiariasSel.id===1 ? this.habilitarRegistroInstituciones = true : this.habilitarRegistroInstituciones = false;         
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroCuentaInstitucionesLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
                this.btnRegistroCuentaInstitucionesLoading=false;
            });
        },


        async registrarBeneficiario(){

            this.btnRegistroBeneficiarioLoading = true;

            /* if(this.datosBeneficiario.cantidadBeneficiarios <= 0){
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`La cantidad de beneficiarios debe ser mayor a 0.`);
            } */

            //Validar municipio
            if(!this.datosBeneficiario.municipiosId || this.datosBeneficiario.municipiosId === 0)
            {
                delete this.datosBeneficiario.municipiosId;
            } 

            if(this.datosBeneficiario.monto.trim().length <= 0){
                this.datosBeneficiario.monto = null;
            }

            if(this.datosBeneficiario.cantidadBeneficiarios.trim().length <= 0){
                this.datosBeneficiario.cantidadBeneficiarios = null;
            }


            this.datosBeneficiario.genero = this.generoSeleccionado.codigo;

            await this.$store
            .dispatch(REGISTRAR_BENEFICIARIO_CNSC, {datos: this.datosBeneficiario})
            .then(res => {
                this.btnLoading = false;
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerBeneficiarios(this.id);
                this.resetForm();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroBeneficiarioLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
            });
            this.btnRegistroBeneficiarioLoading = false;
        },

        //Eliminar un beneficiario
        async eliminarBeneficiario(id){
            console.log(id)  
        },

        //Registrar el beneficiario para institucion
        async registrarInstitucionBeneficiaria(){
            this.btnRegistroBeneficiarioInstitucionLoading = true;
            await this.$store
            .dispatch(REGISTRAR_BENEFICIARIO_INSTITUCION, this.datosInstitucionBeneficiaria)
            .then(res => {
                this.btnLoading = false;
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
                    this.obtenerBeneficiariosInstitucion(this.id);
               
                    this.resetFormInstitucion();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
                }
                this.btnRegistroBeneficiarioInstitucionLoading = false;
            })
            .catch(() => {
                this.$refs.snackalert.SnackbarShow(
                "warning",
                "Alerta",
                `Ha ocurrido un error durante el registro.`
                );
            });
            this.btnRegistroBeneficiarioInstitucionLoading = false;
        },


        //Eliminar una institucion beneficiaria
        async eliminarInstitucionBeneficiaria(id){
            //console.log(proyeccionId)
            this.btnEliminarInstitucionDisabled=true;

             await this.$store
            .dispatch(ELIMINAR_INSTITUCION_BENEFICIARIA_CNS, id)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerBeneficiariosInstitucion(this.id);     
                    //this.totalDistribucionMontos = this.distribucionesMontoSuscrito.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);        
                   // this.resetItems();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarInstitucionDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarInstitucionDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }

    },


    created() {
        this.resetItems(2);
        this.obtenerDepartamentos();
        this.obtenerDatosSeccion(this.id);
        this.obtenerGeneros();
        this.obtenerAreasBeneficiadas();
        this.obtenerEtnias();
        this.obtenerRangosEdad();
        this.obtenerInstituciones();
        this.obtenerTiposIngreso();

        this.obtenerBeneficiarios(this.id);
        this.obtenerBeneficiariosInstitucion(this.id);
    },


     computed: {
        headersBeneficiarios() {
        return [
             {
                text: "Municipio-Departamento",
                align: "start",
                sortable: false,
                value: "departamento"
            },
            {
                text: "Género",
                align: "start",
                sortable: false,
                value: "nombreGenero"
            },
            {
                text: "Área",
                align: "start",
                sortable: true,
                value: "nombreArea"
            },
            {
                text: "Etnia",
                align: "start",
                sortable: true,
                value: "nombreEtnia"
            },
            {
                text: "Grupo etario",
                align: "start",
                sortable: true,
                value: "rango"
            },
            {
                text: "Beneficiados",
                align: "start",
                sortable: true,
                value: "numeroBeneficiados"
            },
           
             {
                text: "Monto GTQ",
                align: "start",
                sortable: false,
                value: "monto"
            },
             {
                text: "Acción",
                align: "start",
                sortable: true,
                value: ""
            },


        ]

        },

        headersInstitucionesBeneficiarias() {
            return [
                {
                    text: "Insittución",
                    align: "start",
                    sortable: false,
                    value: "nombreInstitucion"
                },
                {
                    text: "Rol",
                    align: "start",
                    sortable: true,
                    value: "nombreTipoIngreso"
                },
                 {
                    text: "Departamento",
                    align: "start",
                    sortable: true,
                    value: "departamento"
                },
                 {
                    text: "Municipio",
                    align: "start",
                    sortable: true,
                    value: "municipio"
                },
                {
                    text: "Acción",
                    align: "start",
                    sortable: true,
                    value: ""
                },
            ]
        }
    }


}

</script>